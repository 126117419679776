<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="菜单标题">
				<el-input v-model="form.title" placeholder="请输入菜单标题"></el-input>
			</el-form-item>
			<el-form-item label="菜单级别">
				<el-select v-model="form.level" placeholder="请选择">
				    <el-option
						v-for="item in [ { label : '全部' , value : '' } , { label : '1级菜单' , value : 0 } , { label : '2级菜单' , value : 1 } ]"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
				    </el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button @click="menu_list" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-plus" @click="open_edit_menu( 0 )" circle></el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column
				prop="title"
				label="菜单标题"
			>
				<template slot-scope="scope">
					<el-tag>{{ scope.row.title }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column
				prop="name"
				label="菜单名称"
			>
			</el-table-column>
			<el-table-column
				prop="level"
				label="菜单级别"
			>
				<template slot-scope="scope">
					<span v-if=" scope.row.level === 0 ">父级菜单</span>
					<span v-else>子级菜单</span>
				</template>
			</el-table-column>
			<el-table-column
				prop="status"
				label="启用状态"
			>
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.status === 0 ? 'danger' : 'success'"  disable-transitions>{{ scope.row.status === 0 ? '停用' : '启用' }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column
				prop="createTime"
				label="创建时间"
				>
			</el-table-column>
			<el-table-column
				prop="modifyTime"
				label="最后修改时间"
				>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="220">
			    <template slot-scope="scope">
					<el-button size="mini" v-if=" scope.row.level === 0 " icon="el-icon-s-grid" type="success" plain  @click=" open_sub_menu( scope.row.id ) " style="margin-right:5px">下级菜单</el-button>
					<el-button size="mini" icon="el-icon-edit" type="primary" plain @click=" open_edit_menu( 1 , scope.row ) " style="margin-right:5px">编辑</el-button>
					<el-popconfirm
						title="是否要删除？"
						icon-color="red"
						@confirm="delete_menu( scope.row.id )"
					>
						<el-button slot="reference" size="mini" type="danger" plain icon="el-icon-delete">删除</el-button>
					</el-popconfirm>
			    </template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" :title=" type == 0 ? '新增' : '编辑' " :visible.sync="show" width="450px">
			<el-form ref="form" :model="menu_form" label-width="90px">
				<el-form-item label="菜单标题" prop="title">
					<el-input v-model="menu_form.title" placeholder="请输入菜单标题"></el-input>
				</el-form-item>
				<el-form-item label="菜单名称" prop="name">
					<el-input v-model="menu_form.name" placeholder="请输入菜单名称"></el-input>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input v-model="menu_form.sort" placeholder="请输入排序值"></el-input>
				</el-form-item>
				<el-form-item label="启用状态" prop="status">
					<el-radio-group v-model="menu_form.status">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="0">停用</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="父级菜单" prop="menuId">
					<el-select :disabled=" menu_form.level == 0 ? true : false " v-model="menu_form.parentId" placeholder="请选择父级菜单">
					    <el-option
							v-for="item in parent_menu"
							:key="item.id"
							:label="item.title"
							:value="item.id"
						>
					    </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单级别" prop="level">
					<el-radio-group v-model="menu_form.level">
						<el-radio :label="0" :value="0" >父级</el-radio>
						<el-radio :label="1" :value="1">子级</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="add_permission()" style="margin-right: 20px;">{{ type == 0 ? '新增' : '编辑' }}</el-button>
					<el-button @click="show = false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" title="下级菜单" :visible.sync="sub_show" width="850px">
			<el-table :data="sub_menu_list" border style="width: 100%">
				<el-table-column
					prop="title"
					label="菜单标题"
				>
				</el-table-column>
				<el-table-column
					prop="name"
					label="菜单名称"
				>
				</el-table-column>
				<el-table-column
					prop="level"
					label="菜单级别"
				>
					<template slot-scope="scope">
						<span v-if=" scope.row.level === 0 ">父级菜单</span>
						<span v-else>子级菜单</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="status"
					label="启用状态"
					width="85"
				>
					<template slot-scope="scope">
						<el-tag size="mini" :type="scope.row.status === 0 ? 'danger' : 'success'"  disable-transitions>{{ scope.row.status === 0 ? '停用' : '启用' }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="createTime"
					label="创建时间"
					>
				</el-table-column>
				<el-table-column
					prop="modifyTime"
					label="最后修改时间"
					>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="100">
				    <template slot-scope="scope">
						<el-popconfirm
							title="是否要删除？"
							icon-color="red"
							@confirm="delete_menu_sub( scope.row.id )"
						>
							<el-button slot="reference" size="mini" type="danger" plain icon="el-icon-delete">删除</el-button>
						</el-popconfirm>
				    </template>
				</el-table-column>
			</el-table>
			<div class="page mg_t text_c">
				<el-pagination 
					@current-change="sub_changePage"
					@size-change="sub_sizeChange"
					:page-sizes="[ 10 , 20 , 30 , 40]" 
					:total="sub_menu_total" 
					:currentPage="sub_menu_form.startPage" 
					:pageSize="sub_menu_form.pageSize" 
					background
					layout="total, sizes, prev, pager, next, jumper"
				>
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false,
				form : {
					title: "",
					pageSize : 10,
					startPage : 1,
					level : "",
				},
				total : 0,
				list : [],
				menu_form : {
					level : 0,
					parentId : 0,
					sort : "",
					status : 1,
					name : "",
					title : "",
				},
				type : 0,
				show : false,
				parent_menu : [
					{ 
						id: 0 ,
						title: "请选择父级菜单"
					}
				], 
				sub_show : false,
				sub_menu_list : [],
				sub_menu_form : {
					parentId : "",
					startPage : 1,
					pageSize : 10,
				},
				sub_menu_total : 0,
			}
		},
		created(){
			this.menu_list();
		},
		methods:{
			levelChange( level ){
				this.form.level = level;
			},
			menu_list(){
				this.loading = true;
				this.$api.menu_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			open_edit_menu( type , data ){
				this.type = type;
				if( type == 1 ){
					let id = data.id;
					for( let k in this.menu_form ){
						this.menu_form[k] = data[k];
					}
					this.menu_form.id = id;
				}else{
					this.menu_form =  {
						level : 0,
						parentId : 0,
						sort : "",
						status : 1,
						name : "",
						title : "",
					}
				}
				if( this.parent_menu.length == 1 ){
					this.$api.menu_getParentMenu().then( res => {
						if( res.code == 200 ){
							this.parent_menu = [ ...this.parent_menu , ...res.data ];
							if( this.type == 0 ){
								this.menu_form.parentId = this.parent_menu[0].id;
							}
							this.show = true;
						}
					});
					return;
				}
				if( this.type == 0 ){
					this.menu_form.parentId = this.parent_menu[0].id;
				}
				this.show = true;
			},
			add_permission(){
				this.$api[ this.type == 0 ? 'menu_add' : "menu_update" ]( this.menu_form ).then( res => {
					if( res.code == 200 ){
						this.$message({
							type: 'success',
							message: res.message
						});
						this.show = false;
						this.menu_list();
					}
				});
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.menu_list();
			},
			changePage(e) {
				this.form.startPage = e;
				this.menu_list();
			},
			delete_menu( menuId ){
				this.$api.menu_delete( { menuId } ).then( res => {
					this.$message({
						type: res.code == 200 ? 'success' : 'error',
						message: res.message
					});
					if( res.code == 200 ){
						if( this.list.length == 1 && this.form.startPage > 1 ){
							this.form.startPage = this.form.startPage - 1;
						}
						this.menu_list();
					}
				});
			},
			delete_menu_sub( menuId ){
				this.$api.menu_delete( { menuId } ).then( res => {
					this.$message({
						type: res.code == 200 ? 'success' : 'error',
						message: res.message
					});
					if( res.code == 200 ){
						if( this.list.length == 1 && this.form.startPage > 1 ){
							this.form.startPage = this.form.startPage - 1;
						}
						this.menu_sub();
						this.menu_list();
					}
				});
			},
			open_sub_menu( parentId ){
				this.sub_menu_form.parentId = parentId;
				this.sub_show = true;
				this.menu_sub();
			},
			menu_sub(){
				this.$api.menu_sub( this.sub_menu_form ).then( res => {
					if( res.code == 200 ){
						this.sub_menu_list = res.data.list;
						this.sub_menu_form.startPage = res.data.page;
						this.sub_menu_total = res.data.totalNum;
					}else{
						this.sub_menu_list = [];
						this.sub_menu_form.startPage = 1;
						this.sub_menu_total = 0;
					}
				});
			},
			sub_sizeChange( e ){
				this.sub_menu_form.pageSize = e;
				this.menu_sub();
			},
			sub_changePage(e) {
				this.sub_menu_form.startPage = e;
				this.menu_sub();
			},
		}
	}
</script>
<style lang="scss">
	.el-dialog__header{
		background: linear-gradient(135deg, #6b6be4 , #15d8f1);
	}
	.el-dialog__title{
		color:#fff
	}
	.el-dialog__header{
		.el-dialog__headerbtn{
			.el-dialog__close{
				color: #fff;
			}
		}
	}
</style>
